export interface ImageOptions {
  width?: number;
  height?: number;
  /**
   * https://www.storyblok.com/docs/image-service
   */
  smart?: boolean;
  /**
   * https://www.storyblok.com/docs/image-service#quality-optimization
   */
  quality?: number;
  /**
   * https://www.storyblok.com/docs/image-service#changing-the-format
   */
  format?: "webp" | "jpeg" | "png" | "avif";
}

export const getStoryblokImageUrl = (
  url: string,
  options: ImageOptions = {},
): string => {
  /**
   * Append `/m/` to the image url. Must have that trailing slash.
   */
  const baseUrl = `${url}/m/`;

  // Collect the segments to append after `/m/`
  const segments: string[] = [];

  /**
   * Handle width and height:
   * Resized - Static = /500x500
   * Proportional to Width = /200x0
   * Proportional to Height = /0x200
   */
  const { width, height } = options;
  if (width || height) {
    segments.push(`${width || 0}x${height || 0}`);
  }

  // Handle smart crop
  if (options.smart) {
    segments.push("smart");
  }

  // Handle filters
  const filters: string[] = [];
  if (options.quality !== undefined) {
    filters.push(`quality(${options.quality})`);
  }
  if (options.format) {
    filters.push(`format(${options.format})`);
  }
  if (filters.length > 0) {
    segments.push(`filters:${filters.join(":")}`);
  }

  // Join all the segments with `/` and append to the base URL, example:
  // https://a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg/m/{widthxheight}/{smart}/filters:format(png):quality(80)
  return `${baseUrl}${segments.join("/")}`;
};
